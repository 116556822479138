import React from "react";
import caricature from "./banner-buyer.svg";
export default function BuyerCaricature() {
  return (
    <img
      className={"w-full h-[400px] md:h-[900px]"}
      alt={"building caricature"}
      src={caricature}
      height={300}
      width={355.5}
    />
  );
}
