import React from "react";
import Layout from "../organisms/Layout";
import BuyerAbout from "../organisms/BuyerAbout";
import GrowthProjections from "../organisms/GrowthProjections";
import Testimonial from "../organisms/Testimonial";
import { PageSEO } from "../atoms/SEO";
import BuyerCompanies from "../new_organisms/Companies";
import CrowdStrike_logo from "../images/buyer/companies/CrowdStrike_logo.svg";
import datadoghq from "../images/buyer/companies/datadoghq-ar21.svg";
import Elastic from "../images/buyer/companies/Elastic-NV.svg";
import aws from "../images/buyer/companies/Amazon_Web_Services_Logo.svg";
import Google_Cloud_logo from "../images/buyer/companies/Google_Cloud_logo.svg";
import Gupshup from "../images/buyer/companies/Gupshup.svg";
import PaloAltoNetworks_2020_Logo from "../images/buyer/companies/PaloAltoNetworks_2020_Logo.svg";
import snyk from "../images/buyer/companies/snyk.svg";
import nova from "../images/buyer/companies/nova.svg";
import snowflake from "../images/buyer/companies/snowflake.svg";
import cockroachlabs from "../images/buyer/companies/cockroach-labs.svg";
import sysdig from "../images/buyer/companies/sysdig-inc-logo-vector.svg";
import mongodb from "../images/buyer/companies/mongodb.svg";
import builder from "../images/buyer/companies/builder.svg";
import salesforce from "../images/buyer/companies/salesforce.svg";
import Caricature from "../images/buyer/about/caricature";
import Highlight from "../images/buyer/about/highlight";
import PictorialFeatureCards from "../new_organisms/PictorialFeatureCards";
import driveu from "../images/buyer/caseStudies/driveu.png";
import step1 from "../images/buyer/steps/step1.svg";
import step2 from "../images/buyer/steps/step2.svg";
import step3 from "../images/buyer/steps/step3.svg";
import feature1 from "../images/buyer/features/feature1.svg";
import feature2 from "../images/buyer/features/feature2.svg";
import feature3 from "../images/buyer/features/feature3.svg";
import feature4 from "../images/buyer/features/feature4.svg";
import Steps from "../new_organisms/Steps/secondary";
// import steps from "../new_organisms/Steps";
import FAQs from "../new_organisms/FAQs";
import CaseStudies from "../new_organisms/CaseStudies";
import Schema from "../atoms/Schema";
export default function Buyer({ location }: any) {
  function displayWaitListForm() {
    //display the wait-list modal/form by setting showWaitListFrom to true
    // setShowWaitListForm(true);
    window.open(
      "https://calendly.com/mukund-saas/get-to-know-saaspay?month=2024-04",
      "_blank"
    );
  }

  return (
    <Layout>
      <PageSEO
        path={location.pathname}
        title={
          "SaaSPay Buyer - Access One-Click Credit With Flexible Repayment"
        }
        description={
          "Powering Payments and possibilities. Access one-click credit to buy annual subscriptions, with flexible repayments."
        }
      />
      <Schema
        id={"buyer-page"}
        schema={{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Buyer",
              item: location.pathname,
            },
          ],
        }}
      />
      <BuyerAbout
        Caricature={<Caricature />}
        type="buyer"
        Highlight={<Highlight />}
        title={"A pay later for your SaaS and cloud expenses"}
        subtitle={
          "Postpone tech expenses to unlock capital and do more with it with India's first Buy Now Pay Later for SaaS"
        }
        displayWaitListForm={displayWaitListForm}
        ctaPrimary={"Book a Discovery call"}
        ctaSecondary={"Learn more"}
      />
      <BuyerCompanies
        companies={[
          {
            logo: aws,
            url: "https://netflix.com",
          },
          {
            logo: mongodb,
            url: "https://netflix.com",
          },
          {
            logo: builder,
            url: "https://netflix.com",
          },
          {
            logo: salesforce,
            url: "https://netflix.com",
          },
          {
            logo: snyk,
            url: "https://netflix.com",
          },
          {
            logo: cockroachlabs,
            url: "https://netflix.com",
          },
          {
            logo: snowflake,
            url: "https://netflix.com",
          },
          {
            logo: Gupshup,
            url: "https://netflix.com",
          },
          {
            logo: Google_Cloud_logo,
            url: "https://netflix.com",
          },
          {
            logo: PaloAltoNetworks_2020_Logo,
            url: "https://netflix.com",
          },
          {
            logo: sysdig,
            url: "https://netflix.com",
          },
          {
            logo: Elastic,
            url: "https://netflix.com",
          },
          {
            logo: CrowdStrike_logo,
            url: "https://netflix.com",
          },
          {
            logo: nova,
            url: "https://netflix.com",
          },
          {
            logo: datadoghq,
            url: "https://netflix.com",
          },
        ]}
      />
      <PictorialFeatureCards
        features={[
          {
            title: "*Discounts up to 25%* w/o Upfront Payments ",
            body: "Benefit from monthly payment flexibility while enjoying discounts of up to 30% on annual subscriptions. Simplify your SaaS expenses and optimize budgets to maximize growth.",
            readMore: "https://google.com",
            logo: feature2,
            color: "#EE7D7521",
          },
          {
            title: "*Offset Burn* by 20%",
            body: "Reduce burn by eliminating monthly payables on SaaS & cloud subscriptions across tools being used company-wide. Improve financial metrics & performance with strategic reallocation.",
            readMore: "https://google.com",
            logo: feature3,
            color: "#A897F921",
          },
          {
            title: "Improve *EBITDA* and *Capital Productivity*",
            body: "Mobilize tech budgets to do more with it. Paying later on SaaS & cloud expenses frees up capital to reallocate to marketing, hiring, new initiatives & more. Unlock your capital’s growth potential.",
            readMore: "https://google.com",
            logo: feature4,
            color: "#F9DB8821",
          },
          {
            title: "70+ *SaaS Sellers* Onboard",
            body: "Partnered with 70+ B2B SaaS sellers, avail SaaSPay on the checkout or with your account executives. Enjoy savings & payment flexibility with SaaSPay",
            readMore: "https://google.com",
            logo: feature1,
            color: "#9DE2FD21",
          },
        ]}
        subheading={
          "Mobilise capital parked in SaaS expenses to reduce burn, improve EBITDA & increase capital productivity."
        }
        heading={"*Unlock 30% of your budget locked* in tech expenses"}
      />
      <Steps
        title={"Our *Solution* in Action"}
        subtitle={
          "A financing solution that fits into your existing buying process."
        }
        steps={[
          {
            title: "Choose *SaaSPay*",
            body: "At checkout or with your account executive, opt for SaaSPay as your preferred payment option. Complete onboarding to unlock an instant SaaS payment plan.",
            logo: step1,
          },
          {
            title: "Cha-ching! Receive your *Credit Line*",
            body: "Unlock a notional credit line and use it for IT & tech financing to fuel your growth.",
            logo: step2,
          },
          {
            title: "*SaaS Now, Pay Later*",
            body: "Buy your SaaS tools via SaaSPay without any upfront costs.",
            logo: step3,
          },
        ]}
      />
      <GrowthProjections />

      <Testimonial />

      <CaseStudies
        title={"Discover the impact of *SaaSPay*"}
        subtitle={"Real Businesses. Real Results."}
        studies={[
          // {
          //   title: "How Entropik Saves 30% on their AWS Marketplace Bills",
          //   body: "Entropik saves more than 30% on its entire billing amount on an annual AWS subscription through SaaSPay. The partnership between SaaSPay and Crayon enabled Entropik to optimize its AWS spending and unlock significant savings.",
          //   logo: entropik,
          // },
          {
            title:
              "How DriveU unlocked discounts on CleverTap & Freshworks & improved capital productivity",
            body: "DriveU availed discounts upwards of 30% on tools like CleverTap & Freshworks with 0 upfront payments. By not locking up capital in tech expenses, reducing monthly burn and saving on their tech stack, DriveU improved their liquidity positively impacting cash flow & capital efficiency.",
            logo: driveu,
          },
        ]}
      />
      <FAQs
        title={"*Frequently* asked Questions"}
        subtitle={"FAQs"}
        faqs={[
          {
            question: "What is SaaSPay?",
            answer:
              "SaaSPay is a simple Buy Now Pay Later solution built for SaaS expenses. It helps you finance your SaaS products today and pay back flexibly.",
          },
          {
            question: "Is SaaSPay Right for my Business?",
            answer:
              "SaaSPay helps businesses with significant SaaS & cloud expenses mobilize their capital and do more with it. It also helps you avail discounts without having to pay upfront helping you save more with better flexibility.",
          },
          {
            question: "How can I buy via SaaSPay?",
            answer:
              "Opt for SaaSPay as your preferred payment option at the Seller’s checkout or with your account executive. Complete the onboarding to unlock an instant SaaS payment plan.",
          },
          {
            question: "What is SaaSPay’s interest rate?",
            answer:
              "Your interest rate can vary between 0 - 18% based on your repayment plan. But clubbed with the annual subscription discount, you end up saving 10% or more with SaaSPay.",
          },
          {
            question: "Can I speak to someone at SaaSPay?",
            answer:
              "Yes, for sales-related queries, you can email us at sales@saaspay.app. For product support, please email us at support@saaspay.app",
          },
          {
            question: "Are you a SaaS Seller?",
            answer:
              "Browse our exclusive SaaS seller partner offerings, and unlock growth with SaaSPay.",
          },
        ]}
      />
      {/* <BuyerSavings /> */}
      {/*<BuyerHowItWorks/>*/}

      {/*<BuyerWhyBuy/>*/}
      {/*<AboutUsOurSuccessStories buyerPage={true}/>*/}

      {/*<GetInTouch buyer={true} title={'Become a SaaSPay Empowered Buyer'} desc={'Get in touch to know how you can buy SaaS with SaaSPay’s BNPL'} buttonText={'Grab a coffee with SaaSPay'}/>*/}
    </Layout>
  );
}
