import React from "react";
import Wrapper from "../../atoms/Wrapper";
import Text from "../../atoms/Text";
import Container from "../../atoms/Container";
import TestimonialIcon from "../../images/buyer/testimonial/testimonial.svg";
import Quotes from "../../images/buyer/testimonial/quotes.svg";

export default function Testimonial() {
  return (
    <Wrapper className={"px-4 xl:px-0"}>
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full h-40 md:h-48 items-center justify-center">
          <Text
            className={"text-4xl md:text-6xl gilroy-bold"}
            text="Don’t just take it from us"
          />
          <h1 className="gilroy-regular"></h1>
        </div>
        <div className="flex w-full justify-center flex-col h-[800px] md:h-[500px] sm:flex-col md:flex-row sm:h-[800px]">
          <div className="flex w-full md:max-w-xl md:h-full flex-col  md:p-12 sm:w-full sm:h-[50%] relative">
            <p className="gilroy-regular text-[20px] text-[#1A202C]">
              DriveU smoothly purchased CleverTap & Freshworks annual
              subscriptions using SaaSPay's BNPL. We were particularly happy
              with the discounts we received on annual subscription payments
              without any impact on our cash balance. Routing such transactions
              through SaaSPay can greatly improve the liquidity of a company. A
              must-try for seamless procurement experiences
            </p>
            <img
              src={Quotes}
              alt={"TestimonialIcon"}
              className="top-7 left-3 absolute w-[60px] hidden md:block"
            />
            <p className="mt-6 gilroy-bold text-[20px]">Sreejith</p>
            <p className="gilroy-light text-[16px]">
              Director of Finance at DriveU
            </p>
          </div>
          <div className="flex md:w-[40%] md:h-full items-center justify-center sm:w-full sm:h-[50%] mt-5 md:mt-0 mb-4 xl:mb-0">
            {" "}
            <img
              src={TestimonialIcon}
              alt={"TestimonialIcon"}
              className="sm:max-w-[315px] md:max-w-[395px]"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
