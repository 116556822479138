import React, { useEffect, useState } from "react";

import Text from "../../atoms/Text";
import RevealAnimation from "../../atoms/RevealAnimation";

// import Button from "../../atoms/Button";
// import saasSpends from "../../images/buyer/growthProjections/creditCalculator/saasSpends.svg";
// import savingsWithSaasPay from "../../images/buyer/growthProjections/creditCalculator/savingsWithSaasPay.svg";
// import { useForm, useWatch } from "react-hook-form";
// import CountUp, { useCountUp } from 'react-countup';
// import { Bar } from 'react-chartjs-2';
import PieChart from "../../atoms/PieChart";
import computer from "../../images/buyer/growthProjections/computer.svg";
import BoldSplitTitle from "../../atoms/BoldSplitTitle";
import Button from "../../atoms/Button";
type PROPS_STYLE = {};
const MIN_ANNUAL_SAAS_SPENDING = 0;
const MIN_TOTAL_FUNDING_RAISED = 0;
const MAX_ANNUAL_SAAS_SPENDING = 1000000;
const MAX_TOTAL_FUNDING_RAISED = 10000000;

const TERMS_OF_SPREAD = ["Yearly EMI", "Quarterly", "Half-yearly"];
const COEFFICIENT = [1, 4, 2];
const INSTALLMENTS = [12, 4, 2];
function getCreditLine(funding: number) {
  return funding * 0.05;
}

export default function CreditCalculator(props: PROPS_STYLE) {
  // initial states
  const [totalFundingRaised, setTotalFundingRaised] = useState(7500000);
  const [annualSaaSSpending, setAnnualSaaSSpending] = useState(250000);
  const [termsOfSpread, setTermsOfSpread] = useState(0);

  return (
    <div className={"max-w-4xl mx-auto"}>
      <div
        className={
          "rounded-2xl gap-6 md:gap-16 bg-white px-9 py-12 md:p-12 mb-5 md:mb-2 flex flex-wrap md:flex-nowrap items-center justify-between"
        }
      >
        <div className={"max-w-sm"}>
          <div>
            <BoldSplitTitle
              bold={(text) => (
                <Text
                  className={"text-3xl md:text-6xl gilroy-bold"}
                  text={text}
                />
              )}
              normal={(text) => (
                <Text
                  className={"text-3xl md:text-6xl gilroy-regular"}
                  text={text}
                />
              )}
              text={"*Credit* Calculator"}
            />
          </div>
          {/*<div className={'text-justify md:text-left mt-1 md:mt-0'}>*/}
          {/*    <Text text={'Here\'s how much you can spend on SaaS without paying anything for 12 months'} className={'opacity-50 font-inter md:text-lg'}/>*/}
          {/*</div>*/}
        </div>
        <div className="flex-col flex md:hidden">
          <div className={"mb-2 "}>
            <Text
              text={"Credit Line Allocated"}
              className={"text-lg gilroy-semibold  opacity-50"}
            />
          </div>
          <div className={"mb-6 "}>
            <Text
              text={`₹${getCreditLine(totalFundingRaised).toLocaleString()}`}
              className={"text-[42px] font-inter font-semibold "}
            />
          </div>
          <div className={"mb-5"}>
            <div className={"mb-2 "}>
              <Text
                text={"Savings"}
                className={"text-lg gilroy-semibold opacity-50 "}
              />
            </div>
            <div className={" "}>
              <Text
                text={`₹${Math.round(
                  0.32 * totalFundingRaised
                ).toLocaleString()}`}
                className={"font-inter text-lg font-bold "}
              />
            </div>
          </div>
          <div className={"mb-5"}>
            <div className={"mb-2"}>
              <Text
                text={"Installment amount"}
                className={"text-lg gilroy-semibold opacity-50"}
              />
            </div>
            <div className={""}>
              <Text
                text={
                  annualSaaSSpending > totalFundingRaised * 0.05
                    ? "Get in touch"
                    : `₹${Math.round(
                        (annualSaaSSpending * 1.1) / COEFFICIENT[termsOfSpread]
                      ).toLocaleString()}`
                }
                className={"font-inter text-lg font-bold "}
              />
            </div>
          </div>
          <div className={"mb-5"}>
            <div className={"mb-2"}>
              <Text
                text={"No. of installments"}
                className={"text-lg gilroy-semibold opacity-50"}
              />
            </div>
            <div className={""}>
              <Text
                text={INSTALLMENTS[termsOfSpread].toString()}
                className={"font-inter text-lg font-bold "}
              />
            </div>
          </div>
        </div>
        <div className={"flex flex-col gap-12 w-full"}>
          <div>
            <div className={"flex gap-2 items-center justify-between mb-2"}>
              <Text
                className={
                  "text-sm md:text-base lg:text-lg font-inter font-medium"
                }
                text={"Total Funding Raised"}
              />
              <Text
                className={
                  "text-xs sm:text-sm md:text-base lg:text-lg font-inter font-bold"
                }
                text={`₹${totalFundingRaised.toLocaleString()}`}
              />
            </div>
            <input
              min={MIN_TOTAL_FUNDING_RAISED}
              max={MAX_TOTAL_FUNDING_RAISED}
              step={10000}
              onChange={(e) => setTotalFundingRaised(parseInt(e.target.value))}
              value={totalFundingRaised}
              className={"w-full range-slider"}
              type={"range"}
            />
          </div>
          <div>
            <div className={"flex  items-center justify-between mb-2"}>
              <Text
                className={
                  "text-sm  md:text-base lg:text-lg font-inter font-medium"
                }
                text={"Total SaaS Expenditure"}
              />
              <Text
                className={
                  "text-xs sm:text-sm md:text-base lg:text-lg font-inter font-bold"
                }
                text={`₹${annualSaaSSpending.toLocaleString()}`}
              />
            </div>
            <input
              min={MIN_ANNUAL_SAAS_SPENDING}
              max={MAX_ANNUAL_SAAS_SPENDING}
              step={10000}
              onChange={(e) => setAnnualSaaSSpending(parseInt(e.target.value))}
              value={annualSaaSSpending}
              className={"w-full range-slider"}
              type={"range"}
            />
          </div>
          <div>
            <div className={"flex items-center justify-between mb-2"}>
              <Text
                className={
                  "text-sm  md:text-base lg:text-lg font-inter font-medium"
                }
                text={"Expected Payment Frequency"}
              />
              <Text
                className={
                  "text-xs sm:text-sm md:text-base lg:text-lg font-inter font-bold"
                }
                text={TERMS_OF_SPREAD[termsOfSpread]}
              />
            </div>
            <input
              max={TERMS_OF_SPREAD.length - 1}
              min={0}
              step={1}
              onChange={(e) => setTermsOfSpread(parseInt(e.target.value))}
              value={termsOfSpread}
              className={"w-full range-slider"}
              type={"range"}
            />
          </div>
        </div>
      </div>
      <div
        className={
          "rounded-2xl bg-white px-9 md:px-12 py-12 md:py-9 relative hidden md:block"
        }
      >
        <div className={"mb-1  text-center md:text-left"}>
          <Text
            text={"Credit Line Allocated"}
            className={"text-lg gilroy-semibold  opacity-50"}
          />
        </div>
        <div className={"mb-8  text-center md:text-left"}>
          <Text
            text={`₹${getCreditLine(totalFundingRaised).toLocaleString()}`}
            className={"text-2xl md:text-[42px] font-inter font-semibold "}
          />
        </div>
        <div
          className={
            "flex justify-center md:justify-start flex-wrap gap-4 md:gap-10 lg:gap-32 "
          }
        >
          <div>
            <div className={"mb-1 text-center md:text-left"}>
              <Text
                text={"Savings"}
                className={"text-lg gilroy-semibold opacity-50 "}
              />
            </div>
            <div className={" text-center md:text-left"}>
              <Text
                text={`₹${Math.round(
                  0.32 * totalFundingRaised
                ).toLocaleString()}`}
                className={"font-inter text-lg font-bold "}
              />
            </div>
          </div>
          <div>
            <div className={"mb-1"}>
              <Text
                text={"Installment amount"}
                className={"text-lg gilroy-semibold opacity-50"}
              />
            </div>
            <div className={"text-center md:text-left"}>
              <Text
                text={
                  annualSaaSSpending > totalFundingRaised * 0.05
                    ? "Get in touch"
                    : `₹${Math.round(
                        (annualSaaSSpending * 1.1) / COEFFICIENT[termsOfSpread]
                      ).toLocaleString()}`
                }
                className={"font-inter text-lg font-bold "}
              />
            </div>
          </div>
          <div>
            <div className={"mb-1"}>
              <Text
                text={"No. of installments"}
                className={"text-lg gilroy-semibold opacity-50"}
              />
            </div>
            <div className={"text-center md:text-left"}>
              <Text
                text={INSTALLMENTS[termsOfSpread].toString()}
                className={"font-inter text-lg font-bold "}
              />
            </div>
          </div>
        </div>
        <img
          src={computer}
          className={
            "right-2 bottom-2 lg:right-0 mt-10 md:mt-0 lg:translate-x-1/3 lg:bottom-0 md:absolute md:w-auto max-w-[216px] w-2/3 mx-auto"
          }
        />
      </div>
      <div className="flex items-center justify-center w-full h-14 mt-8">
        <Button
          id={"buyer-about"}
          className={"!rounded-full"}
          onClick={() =>
            window.open("https://form.jotform.com/240982279984475", "_blank")
          }
          height={50}
          width={180}
          variant={"primary"}
        >
          <Text
            className={"!text-white !text-xs md:!text-sm"}
            textStyle={"inter-400-14-20"}
            text={"Request a Demo"}
          />
        </Button>
      </div>
    </div>
  );
}
