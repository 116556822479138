import React from "react";
import Text from "../../atoms/Text";
import graph from "../../images/buyer/growthProjections/Graph.png";
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";
import RevealAnimation from "../../atoms/RevealAnimation";
import Button from "../../atoms/Button";
import CreditCalculator from "../../molecules/CreditCalculator";
import BannerImg from "../../images/saaspay-aws/banner.svg";
export default function GrowthProjections() {
  return (
    <Wrapper className={"relative px-4 xl:px-0"}>
      <div className=" w-full h-full absolute top-0 left-0 -z-10  overflow-hidden">
        <img src={BannerImg} className="w-full h-full object-cover" />
      </div>
      <Container className="py-14 md:py-28">
        <CreditCalculator />
      </Container>
    </Wrapper>
  );
}
