import React from "react";
import Highlight from "../../../images/buyer/about/highlight-svg.svg";
export default function BuyerCaricature() {
  return (
    <img
      className={"w-[230px] h-5 absolute top-[55px] left-16 hidden md:block"}
      alt={"building caricature"}
      src={Highlight}
    />
  );
}
